import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import { MDBCard, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBBtn, MDBContainer, MDBCardHeader, MDBIcon, MDBAnimation } from "mdb-react-ui-kit";
import versionsService from "../../services/versions";
import notificationService from "../../services/notifications";
import CurrentVersionController from "../../Controller/CurrentVersionController";
import imagenCristal from "../../img/cristal-expogame.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTZ from "moment-timezone";

const Notifications = () => {

    const navigate = useNavigate();

    const [notificationData, setNotificationData] = useState([]);

    const [state, setState] = useState(localStorage.getItem('state'));

    const [gamer, setGamer] = useState({
        id: null,
        gamerName: "",
        gamerEmail: "",
        gamerPassword: "",
        gamerCurrentScore: 0,
        gamerExperiencePoints: 0,
        avatar_id: 1,
    });

    const handleDataLoad = async () => {
        const notificationResult = await notificationService.getAllGamerNotificationsByGamer(gamer.id)
        let filtered = [];
        if (notificationResult.length > 5) {
            filtered = notificationResult.slice(Math.max(notificationResult.length - 5, 0))
        } else {
            filtered = notificationResult;
        }
        setNotificationData(filtered)
    }

    const handleEntryDelete = async (id) => {
        const result = await notificationService.hideGamerNotification(id);
        if (result) {
            handleDataLoad();
        }
    }

    useEffect(() => {

        if (!state) {
            navigate('/login');
        } else {
            const newGamer = JSON.parse(atob(state));
            setGamer(newGamer);
        }

    }, []);

    useEffect(() => {
        if (gamer.id != null) {
            handleDataLoad();
        }
    }, [gamer]);

    return (
        <>

            <div className="page-cubes d-flex flex-column min-vh-100">

                <MenuComponent onProfile={false} />
                <MDBContainer className="my-3">
                    {
                        notificationData.length > 0 && (
                            notificationData.map((notification, index) => {
                                let formattedDate = moment(notification.created_at.date)
                                //remove 4 hours from date
                                formattedDate = momentTZ(formattedDate).subtract(4, 'hours').format('DD/MM/YYYY HH:mm')

                                return (
                                    <MDBRow key={index} className="mb-3">
                                        <MDBCol>
                                            <MDBAnimation
                                                animation="fade-in"
                                                duration={200}
                                                delay={index * 200}>
                                                <MDBCard className="text-center">
                                                    <MDBCardHeader className="d-flex justify-content-between">
                                                        <strong>
                                                            {notification.gamerNotificationTitle}
                                                        </strong>
                                                        <span>
                                                            <span
                                                                className="me-4"
                                                            >
                                                                {formattedDate}
                                                            </span>
                                                            <span>
                                                                <MDBBtn color="light" rounded size="sm" onClick={() => {
                                                                    handleEntryDelete(notification.id)
                                                                    handleDataLoad()
                                                                }}>
                                                                    <MDBIcon
                                                                        icon="times"
                                                                    />
                                                                </MDBBtn>
                                                            </span>
                                                        </span>
                                                    </MDBCardHeader>
                                                    <MDBCardBody>
                                                        <MDBCardText className="text-start fs-6">
                                                            {notification.gamerNotificationMessage}
                                                        </MDBCardText>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBAnimation>
                                        </MDBCol>
                                    </MDBRow>
                                )
                            })
                        )
                    }
                    {
                        notificationData.length === 0 && (
                            <MDBRow className="mb-3">
                                <MDBCol>
                                    <MDBAnimation
                                        animation="fade-in"
                                        start="onLoad"
                                        duration={200}
                                        delay={100}>
                                        <MDBCard className="text-center">
                                            <MDBCardHeader className="d-flex justify-content-between">
                                                <strong>
                                                    No tienes notificaciones
                                                </strong>
                                                <span>
                                                    <span
                                                        className="me-4"
                                                    >
                                                        Ahora
                                                    </span>
                                                </span>
                                            </MDBCardHeader>
                                            <MDBCardBody>
                                                <MDBCardText className="text-start">
                                                    Nuevas notificaciones estarán disponibles aquí
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                        )
                    }
                </MDBContainer>
            </div>
        </>
    )
}

export default Notifications