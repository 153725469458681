import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import { MDBCard, MDBContainer, MDBRow, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import versionsService from "../../services/versions";
import zoneService from "../../services/zones";
import CurrentVersionController from "../../Controller/CurrentVersionController"
import { useNavigate } from "react-router-dom";
import iconZone from '../../img/v2/icon-zone.png';
const Zone = () => {
  const navigate = useNavigate();
  const [zones, setZones] = useState([]);
  const [gamer, setGamer] = useState({});
  const [state, setState] = useState(localStorage.getItem('state'));
  const handleVersionDataLoad = async () => {
    const verifyCurrentVersion = await CurrentVersionController();
    if (verifyCurrentVersion) {

      const newZones = await zoneService.getZonesByVersion(verifyCurrentVersion.id)

      setZones(
        newZones.filter(
          (zone) => zone.zoneType !== 'Premio'
        )
      )
    }
  }
  useEffect(() => {
    handleVersionDataLoad();
  }, [zones])

  const handleExternalURL = () => {
    window.open('https://pasaporte.expogamechile.cl/premios/', '_blank');
  }

  useEffect(() => {

    if (!state) {
      navigate('/login');
    } else {
      const newGamer = JSON.parse(atob(state));
      setGamer(newGamer);
    }

  }, []);
  return (
    <>

      <div className="page-cubes min-vh-100">

        <MenuComponent onProfile={false} />

        <MDBContainer className="mb-5">

          <MDBRow>
            <h1 className="text-center my-4 v2 regular">Zonas</h1>
          </MDBRow>
          <MDBRow className="row-cols-1 row-cols-lg-2 g-3 pb-5 mb-3">
            {
              zones.map(
                (zone, key) => (
                  <MDBCol className="">
                    <MDBCard className="zone">
                      <MDBCardBody className='text-dark d-flex justify-content-between align-items-center p-2'>
                        <div className="d-flex align-items-center">
                          <img
                            src={iconZone}
                            alt="Zona"
                            className="me-3"
                            minWidth={60}
                          />
                          <h6 className="fw-bold mb-0">{zone.zoneName}</h6>
                        </div>
                        <MDBBtn className='button-primary' outline size="sm" color='dark' onClick={() => {
                          if (zone.id) {
                            localStorage.setItem('zone', JSON.stringify(zone));
                            navigate('/challenges');
                          }
                        }}> Ver retos</MDBBtn>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                )
              )
            }
          </MDBRow>
        </MDBContainer>
        <div className="page-cubes position-fixed bottom-0 w-100 border-top">
          <MDBRow className="mx-2">
            <MDBCol className="d-flex justify-content-between align-items-center py-4">
              <button className='v2 secondary px-4 py-2' onClick={() => { navigate('/home') }}>
                <span className="v2">Volver</span>
              </button>
              <button className='v2 terciary px-4 py-2' onClick={handleExternalURL}>
                <span className="v2">Premios</span>
              </button>
            </MDBCol>
          </MDBRow>
        </div>
      </div>

    </>
  )
}

export default Zone