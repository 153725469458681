import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBInputGroup,
} from "mdb-react-ui-kit";
import imagenText from '../../img/textos/nuevacontrasena.png';
import imagenCube from '../../img/v2/cristal-1.png';
import userService from "../../services/users";

const NewPassword = () => {

  const navigate = useNavigate();
  const { id, token } = useParams();
  const [validURL, setValidURL] = useState(false);
  const [gamerData, setGamerData] = useState({});
  const [centredModal, setCentredModal] = useState(false);
  const [goToLogin, setGoToLogin] = useState(false);
  const [formData, setFormData] = useState({
    loginPassword: '',
    loginPasswordConfirm: '',
  });

  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');
  const [varyingTitle, setVaryingTitle] = useState('');
  const [passwordIsVisible1, setPasswordIsVisible1] = useState(false);
  const [passwordIsVisible2, setPasswordIsVisible2] = useState(false);
  const [inputFocussed1, setInputFocussed1] = useState(false);
  const [inputFocussed2, setInputFocussed2] = useState(false);

  const btnLogin = () => {
    navigate('/login');
  }

  const toggleShow = () => setCentredModal(!centredModal);

  const handleURLValidity = async () => {
    const response = await userService.checkGamerPasswordRecoveryRequest(
      {
        id: id,
        token: token
      }
    );
    if (response.status === 400) {

      setVaryingMessage(response.messages.error),
        setVaryingTitle("Error"),
        setErrorModal(!errorModal)
      navigate('/login');
    } else {
      setGamerData(response);
      setValidURL(true);
    }
  }

  const handleSubmit = async () => {
    const response = await userService.updateGamerPassword(
      {
        gamerEmail: gamerData.gamerEmail,
        gamerPassword: formData.loginPassword,
        gamerPasswordConfirm: formData.loginPasswordConfirm,
        gamerPasswordRecoveryToken: token
      }
    );
    if (response.status === 400) {

      setVaryingMessage(response.messages.error),
        setVaryingTitle("Error"),
        setErrorModal(!errorModal)
    } else {

      setVaryingMessage("Se ha actualizado la contraseña correctamente"),
        setVaryingTitle("Éxito"),
        setErrorModal(!errorModal)
      setGoToLogin(true);
    }
  }

  useEffect(() => {
    if (goToLogin && !errorModal) {
      navigate('/login');
    }
  }, [goToLogin, errorModal])

  useEffect(() => {
    handleURLValidity();
  }, []);

  return (
    <div className="page-cubes d-flex flex-column min-vh-100">
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>

          <MDBRow>
            <MDBCol className="text-center">
              <h1 className="v2 regular">
                Nueva contraseña
              </h1>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol className="col-md-6 col-12 mx-auto">

              <form action="" className="mb-4">

                <p className="v2 bold">
                  Ingresar tu nueva contraseña
                </p>

                <MDBInputGroup className='d-block mb-4 input-password'>
                  <MDBInput
                    className='form-control'
                    type={passwordIsVisible1 ? 'text' : 'password'}
                    id="loginPassword"
                    name="loginPassword"
                    label="Ingresar Contraseña"
                    onFocus={() => setInputFocussed1(true)}
                    onBlur={() => setInputFocussed1(false)}
                    value={formData.loginPassword}
                    onChange={e => setFormData({ ...formData, loginPassword: e.target.value })}
                  />
                  <MDBBtn
                    className={inputFocussed1 ? 'icon-button-eye password-selected' : 'icon-button-eye'}
                    onClick={() => setPasswordIsVisible1(!passwordIsVisible1)}
                    type="button"
                  >
                    <MDBIcon icon={passwordIsVisible1 ? 'eye-slash' : 'eye'} />
                  </MDBBtn>
                </MDBInputGroup>

                <p className="v2 bold">
                  Confirmar tu nueva contraseña
                </p>

                <MDBInputGroup className='d-block mb-4 input-password'>
                  <MDBInput
                    className='form-control'
                    type={passwordIsVisible2 ? 'text' : 'password'}
                    id="loginPasswordConfirm"
                    name="loginPasswordConfirm"
                    label="Ingresar Contraseña"
                    onFocus={() => setInputFocussed2(true)}
                    onBlur={() => setInputFocussed2(false)}
                    value={formData.loginPasswordConfirm}
                    onChange={e => setFormData({ ...formData, loginPasswordConfirm: e.target.value })}
                  />
                  <MDBBtn
                    className={inputFocussed2 ? 'icon-button-eye password-selected2' : 'icon-button-eye'}
                    onClick={() => setPasswordIsVisible2(!passwordIsVisible2)}
                    type="button"
                  >
                    <MDBIcon icon={passwordIsVisible2 ? 'eye-slash' : 'eye'} />
                  </MDBBtn>
                </MDBInputGroup>

                <div className="text-center">
                  <button className='v2 primary mx-4' onClick={handleSubmit} type="button">
                    <span className="d-flex justify-content-center align-items-center p-4 v2">Crear</span>
                  </button>
                </div>
              </form>
            </MDBCol>
          </MDBRow>

          <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
              <MDBModalContent className="text-center">
                <MDBModalHeader className="border-0">

                  <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <img src={imagenCube} alt="" className="mx-auto" />
                  <p>
                    Tu contraseña se ha actualizado con éxito.
                  </p>
                </MDBModalBody>
                <MDBModalFooter className="justify-content-center text-center border-0 pb-5">
                  <MDBBtn outline className='button-primary' color='dark' onClick={btnLogin} >Ir al Inicio</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

          <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="text-center">
                    <h1 className="v2 regular">
                      {errorModal && varyingTitle}
                    </h1>
                  </div>
                  <div className='mb-3'>
                    <p className="v2 regular">
                      {errorModal && varyingMessage}
                    </p>
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

        </MDBContainer>
      </div>
    </div>

  );
};
export default NewPassword;