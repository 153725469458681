import React, { useEffect, useState } from "react";
import {
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
  MDBInputGroup,
} from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom';
import imagenPasaporte from '../../img/v2/logo-pasaporte.png';
import imagenCube from '../../img/v2/cristal-1.png';
import imagenText from '../../img/textos/estaslistoparasubirdenivel.png';
import imagenHype from '../../img/v2/logo-hype.png';
import PropTypes from 'prop-types'
import userService from "../../services/users";

const Login = () => {

  const navigate = useNavigate();

  const [fillActive, setFillActive] = useState("tab-login");
  const [gamerSignIn, setGamerSignIn] = useState('');
  const [gamerPassword, setGamerPassword] = useState('');
  const [nonInvasiveModal, setNonInvasiveModal] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [inputFocussed, setInputFocussed] = useState(false);

  const toggleShow = () => setNonInvasiveModal(!nonInvasiveModal);

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  const signIn = async (e) => {
    e.preventDefault();

    let loginData = {
      gamerSignIn: gamerSignIn,
      gamerPassword: gamerPassword
    }

    const result = await userService.gamerSignIn(loginData)

    if (result.status === 400) {
      setVaryingMessage(result.messages.error)
      setErrorModal(true)
    } else {
      localStorage.setItem('state', result.gamer);
      navigate('/home');
    }

  }

  const btnRecoverPassword = () => {
    navigate('/recover-password');
  };

  useEffect(() => {
    localStorage.clear();
  }, [])


  return (
    <div className="page-welcome d-flex flex-column min-vh-100">
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>
          <MDBRow>
            <MDBCol className="col-md-6 mx-auto text-center py-4">
              <div className="d-grid gap-2 justify-content-center mb-4">
                <img src={imagenPasaporte} alt="Pasaporte Expogame" className="mx-auto" />
                <img src={imagenCube} alt="" className="mx-auto mt-4" />
                <h1 className="v2 regular">¿Estás listo para subir de nivel?</h1>
              </div>
              <form onSubmit={signIn}>
                <MDBInput
                  className="mb-4"
                  type="text"
                  id="loginName"
                  label="NOMBRE USUARIO O E-MAIL"
                  onChange={e => setGamerSignIn(e.target.value)}
                  autoFocus
                />

                <MDBInputGroup className='d-block mb-4 input-password'>
                  <MDBInput
                    className='form-control'
                    type={passwordIsVisible ? 'text' : 'password'}
                    id="loginPassword"
                    label="INGRESAR CONTRASEÑA"
                    onFocus={() => setInputFocussed(true)}
                    onBlur={() => setInputFocussed(false)}
                    onChange={e => setGamerPassword(e.target.value)}
                  />
                  <MDBBtn
                    className={inputFocussed ? 'icon-button-eye password-selected' : 'icon-button-eye'}
                    onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                    type="button"
                  >
                    <MDBIcon icon={passwordIsVisible ? 'eye-slash' : 'eye'} />
                  </MDBBtn>
                </MDBInputGroup>

                <MDBRow className="mb-4 text-white">
                  {
                    /* Por ahora no se usa
  
                      <MDBCol className="d-flex justify-content-center">
                        <MDBCheckbox
                          className=" mb-3 mb-md-0"
                          defaultChecked
                          label="Recordar tus datos"
                        />
                      </MDBCol>
                    */
                  }
                  <MDBCol className="d-flex justify-content-center">
                    <a onClick={btnRecoverPassword} className='link text-white'>¿Olvidaste tu Contraseña?</a>
                  </MDBCol>
                </MDBRow>
                <div className="d-grid gap-2">
                  <div className="my-4">
                    <button className='v2 primary mx-4'>
                      <span className="d-flex justify-content-center align-items-center p-4 v2">Iniciar sesión</span>
                    </button>
                  </div>
                  <a href="https://hypeproducciones.cl" target='_blank'><img src={imagenHype} alt="" className="mx-auto mt-4" /></a>
                </div>
              </form>

            </MDBCol>
          </MDBRow>
          <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className='text-center mb-4'>
                    <h1 className="v2 regular">Error</h1>
                  </div>
                  <div className='text-center mx-4 py-4'>
                    <p className='v2 regular'>{errorModal && varyingMessage}</p>
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

        </MDBContainer>
      </div>
    </div>
  );
};

Login.proptypes = {
  setToken: PropTypes.func.isRequired
}

export default Login;
