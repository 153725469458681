import React from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
} from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom';
import imagenCube from '../../img/v2/cristal-1.png';



const Confirmation = () => {

    const navigate = useNavigate();

    const btnFinalizar = () => {
        navigate('/');
    };

    return (
        <div className="page-cubes d-flex flex-column min-vh-100">
            <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol className="col-md-6 mx-auto text-center">
                            <img src={imagenCube} alt="" className="mx-auto" />
                            <div className="my-4">
                                <p className="v2 bold">¡Ya tienes registrado tu <br />Pasaporte Expogame!</p>

                                <p className="v2 regular">Te llegará un correo con el <br />enlace de acceso</p>
                            </div>
                            <button className='v2 primary mx-4 mb-4' onClick={btnFinalizar}>
                                <span className="d-flex justify-content-center align-items-center p-4 v2">Ir al inicio</span>
                            </button>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </div>
    );
}
export default Confirmation;