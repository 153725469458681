import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom';
import imagenPasaporte from '../../img/v2/logo-pasaporte.png';
import imagenCube from '../../img/v2/cristal-1.png';
import imagenText from '../../img/textos/estaslistoparasubirdenivel.png';
import imagenHype from '../../img/v2/logo-hype.png';
import imagenAuspiciador from '../../img/v2/logo-auspiciador.png';

const Start = () => {

  const navigate = useNavigate();

  const btnLogin = () => {
    navigate('/login');
  };

  const btnRegister = () => {
    navigate('/register');
  };


  return (
    <div className="page-welcome d-flex flex-column min-vh-100">
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>
          <MDBRow>
            <MDBCol className="col-md-6 col-12 mx-auto text-center py-4">
              <div className="d-grid gap-2 justify-content-center mb-4">
                <img src={imagenPasaporte} alt="" className="mx-auto img-fluid" />
                <div className="d-flex justify-content-center text-center mt-4">
                  <p className="v2 regular">
                    Powered by
                  </p>
                </div>
                <div className="d-flex justify-content-center text-center">
                  <img src={imagenAuspiciador} alt="" className="thumbnail" />
                </div>
                <img src={imagenCube} alt="" className="mx-auto img-fluid" />
              </div>
              <div className="d-grid gap-2">
                <button className='v2 primary mx-4' onClick={btnLogin}>
                  <span className="d-flex justify-content-center align-items-center p-4 v2">Iniciar sesión</span>
                </button>
                <p className="my-1 v2 regular">¿Eres nuevo?</p>
                <button className='v2 secondary mx-4' onClick={btnRegister}>
                  <span className="d-flex justify-content-center align-items-center p-4 v2">Registrarse</span>
                </button>
                <div className="my-4">
                  <a href="https://hypeproducciones.cl" target='_blank'><img src={imagenHype} alt="" className="mx-auto mt-4" /></a>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
};

export default Start;
