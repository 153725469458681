import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import {
    MDBCard,
    MDBCol,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBBtn,
    MDBContainer,
    MDBCardHeader,
    MDBIcon,
    MDBAnimation,
    MDBAccordion,
    MDBAccordionItem
} from "mdb-react-ui-kit";

import imagenText from '../../img/textos/ayuda.png';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTZ from "moment-timezone";
import { useRef } from "react";

const FAQ = () => {

    const navigate = useNavigate();

    const [accordionState, setAccordionState] = useState(1);

    const [state, setState] = useState(localStorage.getItem('state'));

    const [gamer, setGamer] = useState({
        id: null,
        gamerName: "",
        gamerEmail: "",
        gamerPassword: "",
        gamerCurrentScore: 0,
        gamerExperiencePoints: 0,
        avatar_id: 1,
    });

    const faq = [
        {
            headerTitle: '¿Cuál es el horario para canjear premios en el Expogame Center?',
            content: 'Hasta las 18:30 hrs viernes y sábado. El día Domingo hasta las 18.00 hrs.'
        },
        {
            headerTitle: '¿Cada zona tiene diferentes retos?',
            content: 'Cada zona tiene sus retos específicos.Puedes revisarlos en la sección de Retos del Pasaporte.'
        },
        {
            headerTitle: '¿Cuál es el horario en que están disponibles los retos de las zonas?',
            content: 'De las 13:30hrs hasta las 18:30 hrs los días viernes y sábado. El día domingo hasta las 18.00 hrs.'
        },
        {
            headerTitle: '¿Hay una fecha límite para recolectar cristales y canjear premios?',
            content: 'Si compraste la entrada por un día, dispones hasta las 19:00 hrs o al finalizar el día. Si compraste la entrada para los 3 días, puedes seguir acumulando y canjear el último día del evento.'
        },
        {
            headerTitle: '¿Cómo puedo verificar la cantidad de cristales que he recolectado?',
            content: 'Debes ingresar al perfil del Pasaporte para ver los cristales acumulados y canjeados.'
        },
        {
            headerTitle: '¿Qué debo hacer si pierdo mi pulsera NFC durante el evento?',
            content: 'Una vez puesta la pulsera no debe ser retirada. Si compraste la entrada para los 3 días y la perdiste por alguna razón, debes acercarte al Expogame Center para obtener y vincular una nueva.'
        },
        {
            headerTitle: '¿Es posible transferir los cristales recolectados a otra persona o pulsera?',
            content: 'No, los cristales y pulseras son únicos para el jugador, son intransferibles.'
        },
        {
            headerTitle: '¿Qué sucede si la pulsera NFC no funciona correctamente durante el evento?',
            content: 'Si durante el evento tu pulsera presenta problemas, debes acercarte al Expogame Center.'
        },
        {
            headerTitle: '¿Se puede usar la misma pulsera los 3 días?',
            content: 'Sí, la misma pulsera sirve los 3 días.'
        },
        {
            headerTitle: '¿Cuántos cristales puedo recolectar con mi pulsera NFC?',
            content: 'No existe un límite para recolectar cristales. Si notamos irregularidades de cristales asignados, estos serán eliminados por el administrador de la plataforma.'
        },
        {
            headerTitle: '¿Quién me suma los cristales a mi cuenta una vez que supere un reto?',
            content: 'Los encargados de sumar tus cristales ganados son los validadores. Cerca de tu zona siempre encontrarás un validador disponible para sumar tus cristales ganados.'
        },
        {
            headerTitle: '¿Cuál es el propósito de recolectar cristales?',
            content: 'Canjearlos por productos en la Expogame Center. Los premios podrán visualizarlo desde las notificaciones en el pasaporte o bien acercándote al mesón de la Expogame Center.'
        },
        {
            headerTitle: '¿Cómo canjeo los cristales por premios en la Expogame Center?',
            content: 'Debes acercarte al encargado del Expogame Center y buscar el premio que quieres de acuerdo a la cantidad de cristales recolectados. Al momento del canje tus cristales serán descontados y se entregará tu producto. Al momento del canje se te pedirá tu cédula de identidad.'
        },
        {
            headerTitle: '¿Hasta cuándo puedo canjear mis cristales por premios?',
            content: 'Solo dispones del día del evento. Finalizado Expogame Concepción, todos los cristales de los jugadores serán eliminados, pero la experiencia se mantendrá en tu Pasaporte.'
        },
        {
            headerTitle: '¿Puedo utilizar mi pulsera NFC en otros eventos o después del evento?',
            content: 'No, la pulsera es única para el evento. Si participaste en Concepción y luego vas a Santiago, se asignará una nueva pulsera.'
        },
        {
            headerTitle: '¿Puedo obtener un reembolso si no puedo canjear todos mis cristales?',
            content: 'No, sólo dispones del día del evento. Finalizado Expogame Concepción, todos los cristales de jugador se eliminarán, pero la experiencia se mantendrá en tu usuario.'
        },
        {
            headerTitle: '¿Qué es el Expogame Champions?',
            content: 'Los 4 jugadores con más puntos de experiencia ganados durante este año en Expogame Concepción y Santiago se enfrentarán en una olimpiada de videojuegos llamada Expogame Champions el día 13 de octubre en Expogame Santiago Estación Mapocho, el ganador se llevará el gran premio de un viaje a Los Ángeles California a conocer las instalaciones de Riot Games. De los 4 jugadores 2 serán los que tengan más experiencia en Concepción y los otros 2 en Santiago.'
        },
        {
            headerTitle: '¿Tienes más dudas?',
            content: 'Cualquier otra duda debes acercarte al Expogame Center el día del evento o visitar nuestra página web pasaporte.expogamechile.cl.'
        }
    ]

    useEffect(() => {
        //change class of accordionItem when it is open

        document.querySelectorAll('.accordion-item-open').forEach((item) => {
            item.classList.remove('accordion-item-open');
        });
        document.getElementById(accordionState).parentElement.classList.add('accordion-item-open');

    }, [accordionState]);

    useEffect(() => {

        if (!state) {
            navigate('/login');
        } else {
            const newGamer = JSON.parse(atob(state));
            setGamer(newGamer);
        }

    }, []);

    useEffect(() => {
        if (gamer.id != null) {
            //...
        }
    }, [gamer]);

    return (
        <>
            <div className="page-cubes d-flex flex-column min-vh-100 pb-5">
                <MenuComponent onProfile={false} />
                <MDBContainer className="mt-3 mb-5">
                    <MDBRow>
                        <MDBCol className="col-md-8 col-12 mx-auto">
                            <h6 className="v2 regular">Preguntas frecuentes</h6>
                            <MDBAccordion initialActive={1} className="faq" onChange={
                                (e) => {
                                    setAccordionState(e);
                                }
                            }>
                                {
                                    faq.map((item, index) => {
                                        return (
                                            <MDBAccordionItem
                                                collapseId={index + 1} headerTitle={item.headerTitle} key={index} id={index + 1}
                                            >
                                                {item.content}
                                            </MDBAccordionItem>
                                        )
                                    })
                                }
                            </MDBAccordion>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    )
}

export default FAQ