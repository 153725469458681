import avatar1 from '../img/avatares/avatar-1.png';
import avatar2 from '../img/avatares/avatar-2.png';
import avatar3 from '../img/avatares/avatar-3.png';
import avatar4 from '../img/avatares/avatar-4.png';
import avatar5 from '../img/avatares/avatar-5.png';
import avatar6 from '../img/avatares/avatar-6.png';
import avatar7 from '../img/avatares/avatar-7.png';
import avatar8 from '../img/avatares/avatar-8.png';
import avatar9 from '../img/avatares/avatar-9.png';
import avatar10 from '../img/avatares/avatar-10.png';

const hardcodeAvatar = (avatarId) => {
    const nombre = [
        "Luna",
        "Cosmo",
        "Kelvin",
        "Triberto",
        "Aversachi",
        "Blob Ross",
        "DvaVirtual90",
        "PanconQueso",
        "PentaquilXD",
        "LaBurgerQueen"
    ];

    const avatar = [
        avatar1,
        avatar2,
        avatar3,
        avatar4,
        avatar5,
        avatar6,
        avatar7,
        avatar8,
        avatar9,
        avatar10,
    ];

    const description = [
        'Cazarrecompensas interestelar coleccionista de cristales Expogame. Reconocida y respetada en el mercado negro espacial en donde se comercializan los ítems más preciados de la galaxia.',
        'Aventurero y especialista en la restauración y fabricación de piezas de alta tecnología utilizadas para el transporte galáctico y armamento de contrabando.',
        'Gato cósmico compañero aventurero de Cosmo con la habilidad de hablar todos los idiomas existentes en la galaxia lo que le permite negociar y regatear objetos de alto valor para luego venderlos en el mercado negro.',
        'Campeón y líder del mejor equipo de Esports del circuito galáctico de Expogame. Hábil y tenaz en todos los juegos del multiverso gracias a sus tres personalidades que son capaces de pensar como una al momento de tomar decisiones en el campo de batalla.',
        'Cosplayer y diseñadora de moda galáctica universalmente respetada y reconocida por sus trajes de alta calidad confeccionados con los recursos más caros y buscados del universo.',
        'Artista máximo del cosmos capaz de crear planetas completos con el poder de su pincel así también como destruir a los que él considera que no cumplen los canones visuales y estéticos de la galaxia.',
        'Ágil maestra en el arte del sigilo y la velocidad, con una habilidad excepcional para manipular tecnología de vanguardia en su arsenal y utilizarla para infiltrarse en lugares impenetrables y desarmar a sus enemigos antes de que puedan parpadear.',
        'Un intrépido estratega con una destreza extraordinaria en deportes extremos y habilidades físicas sobresalientes, quien también domina el arte de la ingeniería mecánica para mejorar su equipo deportivo y crear innovadoras herramientas que lo ayuden a superar cualquier desafío en su camino.',
        'Un guerrero implacable, experto en tácticas de combate cuerpo a cuerpo y manejo de armas antiguas y exóticas, con una destreza única en la forja y reparación de sus propias armaduras y armas, lo que le otorga una ventaja letal en el fragor de la batalla.',
        'Una hábil shooter, experta en el manejo de una variedad de armas de fuego y tecnología de puntería avanzada, con una habilidad insuperable para adaptarse a cualquier situación de combate y mantener la precisión letal en el fragor de la batalla.'
    ]

    return {
        id: avatarId,
        avatarURL: avatar[avatarId - 1],
        name: nombre[avatarId - 1],
        description: description[avatarId - 1]
    };
}

export default hardcodeAvatar;