import React, { useEffect } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
} from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom';
import imagenPasaporte from '../../img/v2/logo-pasaporte.png';
import imagenCohete from '../../img/v2/cristal-1.png';
import imagenText from '../../img/textos/preparateparalaaventura.png';
import imagenHype from '../../img/v2/logo-hype.png';
import imagenAuspiciador from '../../img/v2/logo-auspiciador.png';

/*
import userService from '../../services/users';
*/

const HomeBoarding = () => {

    const navigate = useNavigate();

    const btnStart = () => {
        navigate('/start');
    };

    return (
        <div className="page-welcome d-flex flex-column min-vh-100 pt-5">
            <div className="mx-auto pt-lg-5">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol className="col-md-8 col-12 mx-auto text-center py-4">
                            <div className="d-grid justify-content-center">
                                <img src={imagenPasaporte} alt="" className="mx-auto" width={260} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
            <div className="d-flex justify-content-center text-center">
                <p className="v2 regular">
                    Powered by
                </p>
            </div>
            <div className="d-flex justify-content-center text-center">
                <img src={imagenAuspiciador} alt="" className="thumbnail" />
            </div>
            <div className="d-flex justify-content-center text-center">
                <img src={imagenCohete} alt="" className="thumbnail" />
            </div>
            <div className="mx-auto">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol className="col-md-8 col-12 mx-auto text-center py-4">
                            <div className="d-grid gap-2 my-4">
                                <p className="m-lg-4 h4 v2">
                                    Juega, aumenta tu nivel y gana increíbles premios
                                </p>
                            </div>
                            <div className="my-4">
                                <button className='v2 primary mx-4' onClick={btnStart}>
                                    {/* Class to center the text inside button*/}
                                    <span className="d-flex justify-content-center align-items-center p-4 v2">Comenzar</span>
                                </button>

                            </div>
                            <div className="my-4">
                                <a href="https://www.expogamechile.cl" target='_blank'><img src={imagenHype} alt="Expogame Chile" className="mx-auto my-2" width={54} /></a>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </div>
    );
};

export default HomeBoarding;
