import React, { useState, useEffect } from "react";
import {
  MDBRow, MDBCol,
  MDBContainer, MDBNavbar, MDBBtn, MDBIcon, MDBSideNav, MDBSideNavMenu, MDBSideNavLink, MDBSideNavItem, MDBSideNavCollapse,

  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBScrollbar,
  MDBModalBody,
  MDBModalFooter, MDBProgress, MDBProgressBar
} from "mdb-react-ui-kit";
import Login from "./Login";
import MenuComponent from "../MenuComponent";
import hardcodeAvatar from "../../services/hardcodeAvatar";
import imagenPasaporte from '../../img/textos/logo-pasaporte.png';
import imagenCristal from '../../img/v2/cristal-1.png';
import icon01 from '../../img/iconos/icon01.svg';
import icon02 from '../../img/iconos/icon02.svg';
import icon03 from '../../img/iconos/icon03.svg';
import icon04 from '../../img/iconos/icon04.svg';


import { useNavigate, useLocation } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const [gamerCurrentScore, setGamerCurrentScore] = useState(0);
  const [gamerExperiencePoints, setGamerExperiencePoints] = useState(0);
  const [gamerCurrentLevel, setGamerCurrentLevel] = useState(0);
  const [avatarSeleccionado, setAvatarSeleccionado] = useState(null);
  const [gamer, setGamer] = useState({
    id: 0,
    gamerName: "",
    gamerEmail: "",
    gamerPassword: "",
    gamerCurrentScore: 0,
    gamerExperiencePoints: 0,
    avatar_id: 1,
  });
  const [state, setState] = useState(localStorage.getItem('state'));
  const setCurrentScoreAndExperience = async (user_id) => {
    //console.log(user_id);
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    await fetch(process.env.REACT_APP_API_BASEURL + `/gamers/${user_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setAvatarSeleccionado(
          hardcodeAvatar(result.avatar_id)
        );
        setGamerCurrentScore(result.gamerCurrentScore);
        setGamerExperiencePoints(result.gamerExperiencePoints);
      })
      .catch(error => console.log('error', error));
  }

  const baseExp = 500;

  const setCurrentLevel = async () => {
    setGamerCurrentLevel(1 + levelFormula(gamerExperiencePoints, "linear", baseExp))
  }

  const levelFormula = (point, strategy, extra) => {
    let level = 0;
    switch (strategy) {
      case "linear":
        if (extra > 0)
          level = Math.floor(point / extra);
        else
          level = 0;
        break;
      case "power":
        if (extra > 0)
          level = Math.floor(Math.pow(point, extra));
        else
          level = 0;
        break;
      default:
        level = 1;
    }
    return level;
  }

  useEffect(() => {

    if (!state) {
      navigate('/login');
    } else {
      const newGamer = JSON.parse(atob(state));
      setGamer(newGamer);
    }

  }, []);

  useEffect(() => {
    if (gamer.id != 0 && gamer.id != null) {
      setCurrentScoreAndExperience(gamer.id);
    }
  }, [gamer]);

  useEffect(() => {
    setCurrentLevel();
  },
    [gamerCurrentScore]
  );

  const [basicOpen, setBasicOpen] = useState(false);
  const [centredModal, setCentredModal] = useState(false);

  const toggleShow = () => setCentredModal(!centredModal);

  const handleExternalURL = () => {
    window.open('https://pasaporte.expogamechile.cl/premios', '_blank');
  }

  const handleExternalURL2 = () => {
    window.open('https://pasaporte.expogamechile.cl/mapa', '_blank');
  }

  return (
    <>
      <div className="page-cubes d-flex flex-column min-vh-100">
        <MenuComponent onProfile={false} />
        <MDBContainer>
          <MDBRow>
            <MDBCol className="d-flex justify-content-end">
              <div className="tag-level">Lvl {gamerCurrentLevel}</div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="mx-auto col-lg-5 col-md-6 text-center pb-5 px-4">

              <img
                src={avatarSeleccionado?.avatarURL}
                alt=""
                className="mx-auto"
                width={160}
              />
              <h5 className="v2 regular">
                {gamer.gamerName}
              </h5>

              <div className="gamerExperiencePoints mt-4">
                {gamerExperiencePoints}
              </div>


              <h6 className="mb-4 mt-2 v2 mini">PUNTOS DE EXPERIENCIA</h6>

              <div className="mb-2">
                <MDBProgress height="20">
                  <MDBProgressBar
                    width={gamerExperiencePoints % baseExp * 100 / baseExp}
                    valuemin={0}
                    valuemax={baseExp}>
                    {gamerExperiencePoints % baseExp * 100 / baseExp} %
                  </MDBProgressBar>
                </MDBProgress>
              </div>
              <h6 className="mb-4 v2 mini">Pts. para el siguiente nivel</h6>

              <div className="gamerCurrentScore">
                {gamerCurrentScore}{" "}
                <img
                  src={imagenCristal}
                  alt="Cristales acumulados"
                  className="icon-cristal"
                  width={40}
                />
              </div>
              <h6 className="mb-4 v2 mini">Cristales acumulados</h6>

              <div className="d-flex flex-column justify-content-center">
                <MDBBtn
                  className="button-secondary mb-2 w-100"
                  outline
                  color="dark"
                  onClick={() => {
                    navigate("/zones");
                  }}
                  target="_blank"
                >
                  Zona de retos
                </MDBBtn>
                <MDBBtn
                  className="button-primary mb-2 w-100"
                  outline
                  color="dark"
                  onClick={handleExternalURL}
                  target="_blank"
                >
                  Premios
                </MDBBtn>
                <MDBBtn
                  className="button-light mb-2 w-100"
                  outline
                  color="dark"
                  onClick={handleExternalURL2}
                  target="_blank"
                >
                  MAPA DEL EVENTO
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <MDBSideNav
          isOpen={basicOpen}
          absolute
          getOpenState={(e) => setBasicOpen(e)}
          className="px-2 py-4"
        >
          <img
            src={icon02}
            alt="Cerrar Sidebar"
            onClick={() => setBasicOpen(!basicOpen)}
            width={38}
            className="ms-auto d-block me-2"
          />
          <MDBContainer>
            <MDBRow className="d-flex align-items-center">
              <MDBCol size="4" className="px-1">
                <img
                  src={avatarSeleccionado?.avatarURL}
                  alt=""
                  className="mx-auto img-fluid"
                />
              </MDBCol>
              <MDBCol size="8" className="text-left px-1">
                <h5 className="fw-bold mb-0">{gamer.gamerName}</h5>
                <MDBSideNavLink
                  className="edit-profile-link p-0"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Editar mi perfil
                </MDBSideNavLink>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <div
            className="side-close-session text-center"
            style={{ minHeight: "3rem" }}
          >
            <a
              href="http://www.instagram.com/expogamechile.cl"
              target="_self"
            ></a>

            <a
              href="https://www.twitch.tv/hypeproducciones"
              target="_self"
            ></a>

            <a href="https://discord.gg/zYdebkP5AR" target="_self"></a>

            <a
              href="http://www.facebook.com/expogamechile"
              target="_self"
            ></a>
            <hr className="mt-0 mb-2" />
            <MDBSideNavLink
              onClick={() => {
                navigate("/login");
              }}
            >
              <img
                src={icon01}
                alt="Cerrar Sesión"
                width={20}
                className="me-2"
              />{" "}
              Cerrar sesión
            </MDBSideNavLink>
          </div>
        </MDBSideNav>

        <MDBModal
          className="modal-cubes"
          tabIndex="-1"
          show={centredModal}
          setShow={setCentredModal}
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>"Nos estamos preparando para Expogame 2024"</p>
              </MDBModalBody>
              <MDBModalFooter></MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
}
export default Home;