import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import imagenText from "../../img/textos/seleccionatupersonaje.png";
import congratulationsText from "../../img/textos/felicidades.png";
import hardcodeAvatar from "../../services/hardcodeAvatar";
import MenuComponent from "../MenuComponent";


import { useNavigate, useLocation } from "react-router-dom";
const UpdateAvatar = () => {

  const location = useLocation();
  const [avatar, setAvatar] = useState([]);
  useEffect(() => {
    let avatars = [];
    let avatarAmount = 10;
    for (let i = 1; i <= avatarAmount; i++) {
      let currentAvatar = hardcodeAvatar(avatarAmount + 1 - i);
      avatars.push(currentAvatar);
    }
    setAvatar(avatars);

  }, []);

  const navigate = useNavigate();
  const [idAvatarSeleccionado, setIdAvatarSeleccionado] = useState(1);
  const [avatarSeleccionado, setAvatarSeleccionado] = useState({});

  const [state, setState] = useState(localStorage.getItem('state'));
  const [token, setToken] = useState(null);

  const [registeredGamer, setRegisteredGamer] = useState({
    avatar_id: 1,
  })

  useEffect(() => {
    setToken(JSON.parse(atob(state)))
  }, [state]);

  useEffect(() => {
    if (token) {
      setRegisteredGamer(
        {
          avatar_id: token.avatar_id
        }
      )
    }
  },
    [token])

  useEffect(() => {
    let newAvatar = hardcodeAvatar(idAvatarSeleccionado);
    setAvatarSeleccionado(newAvatar)
  }, [idAvatarSeleccionado]);

  const updateAvatar = async (e) => {

    const { name, value } = e.target;

    let gamerId = token.id;

    setRegisteredGamer((prevProps) => ({
      ...prevProps, [name]: value,
      avatar_id: idAvatarSeleccionado
    }))

    const newGamer = {
      ...registeredGamer,
      avatar_id: idAvatarSeleccionado
    }

    var requestOptions = {
      method: 'PATCH',
      body: JSON.stringify(newGamer),
      redirect: 'manual'
    };

    await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/" + gamerId, requestOptions)
      .then(response => response.json())
      .then(result => {
        let payloadGamer = { ...token, avatar_id: idAvatarSeleccionado };
        navigate('/home', { state: { token: btoa(JSON.stringify(payloadGamer)) } })
      })
      .catch(error => console.error(error));

  }

  const btnConfirm = avatarId => {

    setIdAvatarSeleccionado(avatarId)
    setCentredModal(!centredModal)
    setCentredModal2(!centredModal2)
  };

  const [centredModal, setCentredModal] = useState(false);
  //MOSTRAR MODAL CON INFO PERSONAJE
  const toggleShow = avatarId => {
    setCentredModal(!centredModal)
    setIdAvatarSeleccionado(avatarId)
  };
  const [centredModal2, setCentredModal2] = useState(false);
  //MODAL CONFIRMANDO PERSONAJE
  const toggleShow2 = avatarId => {
    setCentredModal2(!centredModal2)
  };


  return (
    <div className="page-cubes d-flex flex-column min-vh-100">
      <MenuComponent onProfile={true} />
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>
          <MDBRow >
            <MDBCol sm={'12'} md={'6'} lg={'8'} className="mx-auto">
              <MDBRow className="text-center row-cols-lg-3 row-cols-2">
                {avatar.map((avatar, index) => (
                  <MDBCol className="my-3" key={index}>
                    <div onClick={(e) => toggleShow(avatar.id)}>
                      {avatar.avatarURL ? (
                        <img
                          src={avatar.avatarURL}
                          alt=""
                          className="mx-auto img-fluid v2 avatar"
                        />
                      ) : (
                        <>
                          <p>Descripción: {avatarSeleccionado.description}</p>
                          <MDBBtn color="dark" onClick={
                            () => toggleShow(avatar.id)
                          }>
                            Escoger
                          </MDBBtn>
                        </>
                      )}
                    </div>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal} >
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader className="border-0">
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShow}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="text-center">
                  <img
                    src={avatarSeleccionado.avatarURL}
                    alt=""
                    className="mx-auto v2 avatar"
                  />

                  <p className="v2 regular my-4">
                    Se elegirá a {avatarSeleccionado.name} ¿Es correcto?
                  </p>

                  <h1 className="v2 avatar">{avatarSeleccionado.name}</h1>
                  <p
                    className="v2 regular"
                  >{avatarSeleccionado.description}</p>

                </MDBModalBody>
                <MDBModalFooter className="justify-content-center text-center border-0 pb-4">
                  <button className='v2 primary mx-4' onClick={(e) => btnConfirm(avatarSeleccionado.id)}>
                    <span className="d-flex justify-content-center align-items-center p-4 v2">Seleccionar</span>
                  </button>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal tabIndex="-1" show={centredModal2} setShow={setCentredModal2} staticBackdrop >
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader className="border-0">

                </MDBModalHeader>
                <MDBModalBody className="text-center">

                  <h1 className="v2 regular">¡Felicidades!</h1>

                  <img
                    src={avatarSeleccionado.avatarURL}
                    alt=""
                    className="mx-auto"
                  />

                  <div className="my-4">
                    <p className="v2 regular">Has escogido a:</p>
                    <h1 className="v2 avatar">{avatarSeleccionado.name}</h1>
                  </div>

                </MDBModalBody>
                <MDBModalFooter className="justify-content-center text-center border-0 py-4">
                  <button className='v2 primary mx-4' onClick={updateAvatar}>
                    <span className="d-flex justify-content-center align-items-center p-4 v2">Finalizar</span>
                  </button>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </MDBContainer>
      </div>
    </div>
  );
};
export default UpdateAvatar;
