import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBSelect,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalDialog,
    MDBModalContent,
    MDBModalFooter,
    MDBModalTitle,
    MDBIcon,
    MDBInputGroup,
} from "mdb-react-ui-kit";
import { MDBInputMask } from 'mdb-react-input-mask';
import { useNavigate } from 'react-router-dom';
import imagenTextoBanner from '../../img/textos/registratupasaporte.png';
import usersService from '../../services/users';

const Register = () => {

    const navigate = useNavigate();

    const [regionId, setRegionId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [region, setRegion] = useState([])
    const [city, setCity] = useState([])
    const [errorModal, setErrorModal] = useState(false);
    const [varyingMessage, setVaryingMessage] = useState('');
    const [passwordIsVisible1, setPasswordIsVisible1] = useState(false);
    const [passwordIsVisible2, setPasswordIsVisible2] = useState(false);
    const [inputFocussed1, setInputFocussed1] = useState(false);
    const [inputFocussed2, setInputFocussed2] = useState(false);

    const [gamer, setGamer] = useState({
        city_id: cityId,
        avatar_id: 1,
        gamerDNI: "",
        gamerPhone: "",
        gamerName: "",
        gamerFullName: "",
        gamerAge: "",
        gamerEmail: "",
        gamerPassword: "",
        gamerPasswordConfirm: ""

    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setGamer((prevProps) => ({
            ...prevProps, [name]: value,
            city_id: cityId
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await usersService.checkRegister(gamer)

        if (response.status === 400) {

            setVaryingMessage(response.messages.error),
                setErrorModal(!errorModal)
        } else {
            navigate('/avatar', {
                state: gamer
            })
        }
    }


    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASEURL + "/states")
            .then((response) => response.json())
            .then((data) => {
                const regions = data.map((item) => ({
                    id: item.id,
                    stateName: item.stateName,
                }))
                setRegion(regions)
            }
            )
    }, [])


    useEffect(() => {
        if (!regionId) return;
        fetch(process.env.REACT_APP_API_BASEURL + "/cities/state/" + regionId)
            .then((response) => response.json())
            .then((data) => {
                const cities = data.map((item) => ({
                    id: item.id,
                    state_id: item.state_id,
                    cityName: item.cityName
                }))
                setCity(cities)
                setCityId(cities[0].id)

            })

    }, [regionId])

    return (
        <div className="page-cubes d-flex flex-column min-vh-100">
            <div className="mt-5">
                <MDBContainer fluid className="banner-bg py-3 text-center">
                    <h1 className='v2 regular'>Registro</h1>
                </MDBContainer>
            </div>
            <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                <MDBContainer className='mb-5'>
                    <MDBRow>
                        <MDBCol className="col-md-5 col-12 mx-auto">
                            <form>
                                <MDBInput
                                    className="mb-3" type="text" name="gamerName" id="gamerName" label="Nombre de Usuario"
                                    value={gamer.gamerName} onChange={handleInputChange}
                                />

                                <MDBInput
                                    className="mb-3" type="text" name="gamerFullName" id="gamerFullName" label="Nombre Completo"
                                    value={gamer.gamerFullName} onChange={handleInputChange}
                                />

                                <MDBInput
                                    className="mb-3" type="email" id="gamerEmail" name="gamerEmail" label="Email"
                                    value={gamer.gamerEmail} onChange={handleInputChange}
                                />

                                <MDBInput
                                    className="" type="text" name="gamerDNI" id="gamerDNI" label="RUT"
                                    value={gamer.gamerDNI} onChange={handleInputChange}
                                />
                                <div className='form-text mb-3 ms-3'>
                                    Con puntos y guión. Ej: 12.345.678-9
                                </div>

                                <MDBInputGroup textBefore='+56' className='input-phone mb-3'>
                                    <MDBInput
                                        type="text" name="gamerPhone" id="gamerPhone" label='912345678'
                                        value={gamer.gamerPhone} onChange={handleInputChange}
                                    />
                                </MDBInputGroup>

                                <MDBInput
                                    className="mb-3 mt-3" type="number" name="gamerAge" id="gamerAge" label="Edad"
                                    value={gamer.gamerAge} onChange={handleInputChange} min={0} step={1}
                                />

                                {
                                    region.length > 0
                                    &&
                                    <MDBSelect
                                        className="mb-3"
                                        onValueChange={(e) => setRegionId(e.value)}
                                        preventFirstSelection
                                        placeholder='Seleccione una región'
                                        data={
                                            region.map(
                                                region =>
                                                (
                                                    {
                                                        text: region.stateName,
                                                        value: region.id,
                                                        defaultSelected: region.id == regionId
                                                    }
                                                )
                                            )
                                        }
                                    />
                                }
                                {
                                    regionId && <MDBSelect
                                        className="mb-3"
                                        onValueChange={(e) => setCityId(e.value)}
                                        label='Ciudad o comuna'
                                        data={city.map(city => (
                                            {
                                                text: city.cityName,
                                                value: city.id,
                                                defaultSelected: city.id == cityId
                                            }
                                        ))}
                                    />
                                }

                                <MDBInputGroup className='d-block mb-4 input-password'>
                                    <MDBInput
                                        className='form-control'
                                        type={passwordIsVisible1 ? 'text' : 'password'}
                                        id="gamerPassword"
                                        name="gamerPassword"
                                        label="Contraseña"
                                        onFocus={() => setInputFocussed1(true)}
                                        onBlur={() => setInputFocussed1(false)}
                                        onChange={handleInputChange}
                                        value={gamer.gamerPassword}
                                    />
                                    <MDBBtn
                                        className={inputFocussed1 ? 'icon-button-eye password-selected' : 'icon-button-eye'}
                                        onClick={() => setPasswordIsVisible1(!passwordIsVisible1)}
                                        type="button"
                                    >
                                        <MDBIcon icon={passwordIsVisible1 ? 'eye-slash' : 'eye'} />
                                    </MDBBtn>
                                </MDBInputGroup>

                                <MDBInputGroup className='d-block mb-4 input-password'>
                                    <MDBInput
                                        className='form-control'
                                        type={passwordIsVisible2 ? 'text' : 'password'}
                                        id="gamerPasswordConfirm"
                                        name="gamerPasswordConfirm"
                                        label="Confirma tu contraseña"
                                        onFocus={() => setInputFocussed2(true)}
                                        onBlur={() => setInputFocussed2(false)}
                                        onChange={handleInputChange}
                                        value={gamer.gamerPasswordConfirm}
                                    />
                                    <MDBBtn
                                        className={inputFocussed2 ? 'icon-button-eye password-selected2' : 'icon-button-eye'}
                                        onClick={() => setPasswordIsVisible2(!passwordIsVisible2)}
                                        type="button"
                                    >
                                        <MDBIcon icon={passwordIsVisible2 ? 'eye-slash' : 'eye'} />
                                    </MDBBtn>
                                </MDBInputGroup>

                                <div className="d-grid gap-2">
                                    <button className='v2 primary mx-4' onClick={handleSubmit}>
                                        <span className="d-flex justify-content-center align-items-center p-4 v2">Siguiente</span>
                                    </button>
                                </div>

                            </form>

                        </MDBCol>

                    </MDBRow>

                    <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
                        <MDBModalDialog centered>
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <div className='text-center mb-4'>
                                        <h1 className="v2 regular">Error</h1>
                                    </div>
                                    <div className='text-center mx-4 py-4'>
                                        <p className='v2 regular'>{errorModal && varyingMessage}</p>
                                    </div>
                                </MDBModalBody>
                                <MDBModalFooter>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                </MDBContainer>
            </div>
        </div>

    )
}

export default Register;