import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomeBoarding from './components/views/HomeBoarding';
import RegisterForm from './components/views/Register';
import Avatar from './components/views/Avatar';
import Login from './components/views/Login';
import RecoverPassword from './components/views/RecoverPassword';
import NewPassword from './components/views/NewPassword';
import Start from './components/views/Start';
import Home from './components/views/Home';
import Profile from './components/views/Profile';
import UserList from './Controller/UserController';
import Confirmation from './components/views/Confirmation';
import UpdateAvatar from './components/views/UpdateAvatar';
import Prizes from './components/views/Prizes';
import Zones from './components/views/Zone'
import Challenges from "./components/views/Challenge"
import Notification from "./components/views/Notifications"
import FAQ from './components/views/FAQ';
function App() {

  return (

    <BrowserRouter basename='/'>
      <Routes>
        <Route exact path='/' element={<HomeBoarding />} />
        <Route path='/start' element={<Start />} />
        <Route path='/register' element={<RegisterForm />} />
        <Route path='/avatar' element={<Avatar />} />
        <Route path='/confirmation' element={<Confirmation />} />
        <Route path='/login' element={<Login />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/update-avatar' element={<UpdateAvatar />} />
        <Route path='/recover-password' element={<RecoverPassword />} />
        <Route path='/new-password/:id/:token' element={<NewPassword />} />
        <Route path='/home' element={<Home />} />
        <Route path='/users' element={<UserList />} />
        <Route path='/prizes' element={<Prizes />} />
        <Route path='/zones' element={<Zones />} />
        <Route path='/challenges' element={<Challenges />} />
        <Route path='/notifications' element={<Notification />} />
        <Route path='/faq' element={<FAQ />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
