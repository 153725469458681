import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle
} from "mdb-react-ui-kit";
import imagenText from "../../img/textos/seleccionatupersonaje.png";
import congratulationsText from "../../img/textos/felicidades.png";
import hardcodeAvatar from "../../services/hardcodeAvatar";

import { useNavigate, useLocation } from "react-router-dom";
import userService from "../../services/users";

const Avatar = () => {

  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');
  const location = useLocation();
  const [avatar, setAvatar] = useState([]);
  useEffect(() => {
    let avatars = [];
    let avatarSize = 10
    for (let i = 1; i <= avatarSize; i++) {
      let currentAvatar = hardcodeAvatar(avatarSize + 1 - i)
      avatars.push(currentAvatar);
    }
    setAvatar(avatars);

  }, []);

  const navigate = useNavigate();
  const [idAvatarSeleccionado, setIdAvatarSeleccionado] = useState(1);
  const [avatarSeleccionado, setAvatarSeleccionado] = useState({});

  useEffect(() => {
    let newAvatar = hardcodeAvatar(idAvatarSeleccionado);
    setAvatarSeleccionado(newAvatar)

  }, [idAvatarSeleccionado]);

  const gamer = location.state;

  const [registeredGamer, setRegisteredGamer] = useState({
    city_id: gamer.city_id,
    avatar_id: gamer.avatar_id,
    gamerFullName: gamer.gamerFullName,
    gamerDNI: gamer.gamerDNI,
    gamerPhone: gamer.gamerPhone,
    gamerName: gamer.gamerName,
    gamerAge: gamer.gamerAge,
    gamerEmail: gamer.gamerEmail,
    gamerPassword: gamer.gamerPassword,
    gamerPasswordConfirm: gamer.gamerPasswordConfirm
  })

  const registerUser = async (e) => {
    const { name, value } = e.target;
    setRegisteredGamer((prevProps) => ({
      ...prevProps, [name]: value,
      avatar_id: idAvatarSeleccionado
    }))
    const newGamer = {
      ...registeredGamer,
      avatar_id: idAvatarSeleccionado
    }

    console.log(newGamer)

    const response = await userService.registerGamer(newGamer);

    if (response.status === 400) {
      console.log(response)
      setVaryingMessage(response.messages.error),
        setErrorModal(!errorModal)
    } else {
      //alert("Usuario registrado correctamente")

      const notification = await userService.notifyGamerRegistration(
        {
          id: response.id
        }
      );

      if (notification.status === 400) {

        setVaryingMessage(notification.messages.error),
          setErrorModal(!errorModal)
      } else {
        navigate('/confirmation')
      }

    }

  }

  const btnConfirm = avatarId => {

    setIdAvatarSeleccionado(avatarId)
    console.log(idAvatarSeleccionado)
    setCentredModal(!centredModal)
    setCentredModal2(!centredModal2)
  };

  const [centredModal, setCentredModal] = useState(false);
  //MOSTRAR MODAL CON INFO PERSONAJE
  const toggleShow = avatarId => {
    setCentredModal(!centredModal)
    setIdAvatarSeleccionado(avatarId)
  };
  const [centredModal2, setCentredModal2] = useState(false);
  //MODAL CONFIRMANDO PERSONAJE
  const toggleShow2 = avatarId => {
    setCentredModal2(!centredModal2)
  };


  return (
    <div className="page-cubes d-flex flex-column min-vh-100">
      <div className="banner-gradient py-lg-4 py-3">
        <MDBContainer fluid className="banner-bg text-center">
          <img src={imagenText} alt="Selecciona tu personaje" className="mx-auto txt-selec-avatar" />
        </MDBContainer>
      </div>
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>
          <MDBRow >
            <MDBCol sm={'12'} md={'6'} lg={'8'} className="mx-auto">
              <MDBRow className="text-center row-cols-lg-3 row-cols-2">
                {avatar.map((avatar, index) => (
                  <MDBCol className="my-3" key={index}>
                    <div onClick={(e) => toggleShow(avatar.id)} >
                      {avatar.avatarURL ? (
                        <img
                          src={avatar.avatarURL}
                          alt=""
                          className="mx-auto img-fluid"
                        />
                      ) : (
                        <>
                          <div>
                            <h6>Nombre Personaje {index + 1}</h6>
                            <p>{avatarSeleccionado.name}</p>
                          </div>
                          <p>{avatarSeleccionado.description}</p>
                          <MDBBtn color="dark" onClick={toggleShow}>
                            Escoger
                          </MDBBtn>
                        </>
                      )}
                    </div>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader className="border-0">
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShow}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="text-center">
                  <img
                    src={avatarSeleccionado.avatarURL}
                    alt=""
                    className="mx-auto avatarimg"
                  />

                  <div className="my-4">
                    <h1 className="v2 avatar">
                      {avatarSeleccionado.name}
                    </h1>
                  </div>
                  <p className="v2 regular">
                    {avatarSeleccionado.description}
                  </p>

                </MDBModalBody>
                <MDBModalFooter className="justify-content-center text-center border-0 py-4">
                  <button className='v2 primary mx-4 mb-4' onClick={(e) => btnConfirm(avatarSeleccionado.id)}>
                    <span className="d-flex justify-content-center align-items-center p-4 v2">SELECCIONAR</span>
                  </button>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          <MDBModal tabIndex="-1" show={centredModal2} setShow={setCentredModal2}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader className="border-0">
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShow2}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="text-center">
                  <img
                    src={avatarSeleccionado.avatarURL}
                    alt=""
                    className="mx-auto avatarimg"
                  />

                  <div className="my-4">
                    <h1 className="v2 regular">
                      ¡Felicidades!
                    </h1>
                  </div>
                  <div className="my-4">
                    <p className="v2 regular">
                      Has escogido a
                    </p>

                    <h1 className="v2 avatar">
                      {avatarSeleccionado.name}
                    </h1>

                  </div>

                </MDBModalBody>
                <MDBModalFooter className="justify-content-center text-center border-0 py-4">
                  <button className='v2 primary mx-4 mb-4' onClick={registerUser}>
                    <span className="d-flex justify-content-center align-items-center p-4 v2">Finalizar</span>
                  </button>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

          <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Error</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className='mb-3'>
                    {errorModal && varyingMessage}
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </MDBContainer>
      </div>
    </div>
  );
};
export default Avatar;
