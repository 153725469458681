import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import { MDBContainer, MDBCard, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import versionsService from "../../services/versions";
import challengeService from "../../services/challenges";
import CurrentVersionController from "../../Controller/CurrentVersionController";
import imagenCristal from "../../img/v2/cristal-1.png";
import { useNavigate } from "react-router-dom";

const Challenge = () => {

  const navigate = useNavigate();
  const zone = JSON.parse(localStorage.getItem('zone'));
  const zone_id = zone.id;
  const [challenges, setChallenges] = useState([]);

  const [state, setState] = useState(localStorage.getItem('state'));
  const [gamer, setGamer] = useState({
    id: 0,
    gamerName: "",
    gamerEmail: "",
    gamerPassword: "",
    gamerCurrentScore: 0,
    gamerExperiencePoints: 0,
    avatar_id: 1,
  });
  const handleChallengeDataLoad = async () => {
    const newChallenges = await challengeService.getChallengeByZone(zone_id);
    setChallenges(newChallenges)
  }
  useEffect(() => {
    handleChallengeDataLoad()
  }, [zone]);

  const handleExternalURL = () => {
    window.open('https://pasaporte.expogamechile.cl/premios/', '_blank');
  }

  useEffect(() => {

    if (!state) {
      navigate('/login');
    } else {
      const newGamer = JSON.parse(atob(state));
      setGamer(newGamer);
    }

  }, []);
  return (
    <>

      <div className="page-cubes min-vh-100">

        <MenuComponent onProfile={false} />

        <MDBContainer className="mb-5">
          <MDBRow>
            <h1 className="text-center my-4 v2 regular">{zone.zoneName}</h1>
          </MDBRow>
          <MDBRow className="row-cols-1 row-cols-lg-2 g-3 pb-5 mb-3">
            {
              challenges.length > 0 &&
              challenges.map((challenge, key) => (

                <MDBCol className="" key={key}>
                  <MDBCard shadow='0' border='dark' background='white' className="challenge">
                    <MDBCardBody className='text-dark d-flex justify-content-between align-items-center'>

                      <div style={{
                        width: '65%'
                      }}>
                        <h6>{challenge.challengeActivity}</h6>

                        <MDBCardText className="fs-6 v2 regular challengeTextWrap">
                          {challenge.challengeDescription}
                        </MDBCardText>
                        <div className={
                          challenge.challengeDifficulty === 'Intermedio' ? 'tag-difficulty tag-difficulty-medium' : (challenge.challengeDifficulty === 'Fácil' ? 'tag-difficulty tag-difficulty-easy' : 'tag-difficulty tag-difficulty-hard')
                        }>
                          {challenge.challengeDifficulty}
                        </div>
                      </div>
                      <div className="gamerCurrentScore2">
                        {challenge.challengeScore}{" "}
                        <img
                          src={imagenCristal}
                          alt="Cristales acumulados"
                          className="icon-cristal2"
                          width={30}
                        />
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              ))
            }

          </MDBRow>
        </MDBContainer>

        <div className="page-cubes position-fixed bottom-0 w-100 border-top">
          <MDBRow className="mx-2">
            <MDBCol className="d-flex justify-content-between align-items-center py-4">
              <button className='v2 secondary px-4 py-2' onClick={() => {
                localStorage.removeItem('zone')
                navigate('/zones')
              }}>
                <span className="v2">Volver</span>
              </button>
              <button className='v2 terciary px-4 py-2' onClick={handleExternalURL}>
                <span className="v2">Premios</span>
              </button>
            </MDBCol>
          </MDBRow>
        </div>

      </div>
    </>
  )
}

export default Challenge