import React, { useState, useEffect } from "react";
import {
    MDBRow, MDBCol,
    MDBContainer, MDBNavbar, MDBBtn, MDBIcon, MDBSideNav, MDBSideNavMenu, MDBSideNavLink, MDBSideNavItem, MDBSideNavCollapse,
    MDBInput, MDBSelect,
    MDBModal,
    MDBModalDialog, MDBModalTitle,
    MDBModalContent,
    MDBModalHeader,
    MDBScrollbar,
    MDBModalBody,
    MDBModalFooter,
} from "mdb-react-ui-kit";
import { MDBInputMask } from 'mdb-react-input-mask';

import Login from "./Login";
import MenuComponent from "../MenuComponent";
import hardcodeAvatar from "../../services/hardcodeAvatar";
import editarPerfil from '../../img/textos/editar-perfil.png';
import icon01 from '../../img/iconos/icon01.svg';
import icon02 from '../../img/iconos/icon02.svg';
import icon03 from '../../img/iconos/icon03.svg';
import icon04 from '../../img/iconos/icon04.svg';

import { useNavigate, useLocation, redirect } from 'react-router-dom';


const Profile = () => {

    const navigate = useNavigate();

    const [regionId, setRegionId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [region, setRegion] = useState([])
    const [city, setCity] = useState([])
    const [firstLoad, setFirstLoad] = useState(true);
    const [state, setState] = useState(localStorage.getItem('state'));
    const [gamer, setGamer] = useState({
        id: 0,
        city_id: "",
        avatar_id: "",
        gamerDNI: "",
        gamerPhone: "",
        gamerAge: "",
        gamerFullName: "",
        gamerName: "",
        gamerEmail: "",
        gamerPassword: ""
    });

    const [updatedGamer, setUpdatedGamer] = useState({
        city_id: "",
        avatar_id: "",
        gamerDNI: "",
        gamerPhone: "",
        gamerAge: "",
        gamerFullName: "",
        gamerName: "",
        gamerEmail: "",
        gamerPassword: ""

    });

    const [avatarSeleccionado, setAvatarSeleccionado] = useState(null);

    const [basicOpen, setBasicOpen] = useState(false);
    const [centredModal, setCentredModal] = useState(false);

    const toggleShow = () => setCentredModal(!centredModal);

    const loadGamerData = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        await fetch(process.env.REACT_APP_API_BASEURL + `/gamers/${gamer.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                let gamerDNI = result.gamerDNI;
                //get last character
                let lastCharacter = gamerDNI.slice(-1);
                //get first 8 characters
                let firstEightCharacters = gamerDNI.slice(0, -1);
                //set firstEightCharacters as a number separated by dots (thousands)
                let firstEightCharactersDots = firstEightCharacters.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
                //concatenate firstEightCharactersDots and lastCharacter
                let gamerDNIFormatted = firstEightCharactersDots + "-" + lastCharacter;
                result.gamerDNI = gamerDNIFormatted;

                result.gamerPassword = "";
                setUpdatedGamer(result);
            })
            .catch(error => console.log('error', error));
    }

    const handleInputChange = (e) => {
        console.log(cityId)
        const { name, value } = e.target;
        setUpdatedGamer({
            ...updatedGamer,
            [name]: value,
            city_id: cityId
        })
    }

    useEffect(() => {
        if (regionId != null) {
            fetch(process.env.REACT_APP_API_BASEURL + "/cities/state/" + regionId)
                .then((response) => response.json())
                .then((data) => {
                    const cities = data.map((item) => ({
                        id: item.id,
                        state_id: item.state_id,
                        cityName: item.cityName
                    }))
                    setCity(cities)
                    if (firstLoad) {
                        setCityId(gamer.city_id);
                        setFirstLoad(false);
                    } else {
                        setCityId(cities[0].id)
                    }
                })
        }
    }, [regionId])

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const updateGamer = async () => {
        console.log(updatedGamer)
        var requestOptions = {
            method: 'PATCH',
            body: JSON.stringify(updatedGamer),
            redirect: 'follow'
        };
        await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/" + gamer.id, requestOptions)
            .then(response => response.json())
            .then(result => { toggleShow() })
            .catch(error => console.error(error));
    }

    const loadStateData = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'manual'
        };
        await fetch(process.env.REACT_APP_API_BASEURL + "/states", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                const regions = data.map((item) => ({
                    id: item.id,
                    stateName: item.stateName,
                }))
                setRegion(regions
                )
            }
            )
        if (updatedGamer.city_id) {
            await fetch(process.env.REACT_APP_API_BASEURL + "/cities/" + updatedGamer.city_id, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setRegionId(data.state_id);
                }
                )
        }
    }

    useEffect(() => {
        if (gamer.id != 0) {
            loadGamerData();
        }
    }, [gamer]);

    useEffect(() => {
        if (!state) {
            navigate('/login');
        } else {
            let newGamer = JSON.parse(atob(state));
            setGamer(newGamer);
        }
    }, []);

    useEffect(() => {
        console.log(updatedGamer)
        let newAvatar = hardcodeAvatar(updatedGamer.avatar_id);
        setAvatarSeleccionado(newAvatar)
        loadStateData();
    }, [updatedGamer]);

    return (
        <div className="page-cubes d-flex flex-column min-vh-100">
            <MenuComponent onProfile={true} />
            <MDBContainer className="mb-5">
                <MDBRow>
                    <MDBCol className="mx-auto mt-4 col-md-6 text-center">

                        <div className="d-flex justify-content-between align-items-center px-3">
                            <button
                                style={
                                    {
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                    }
                                }
                                onClick={
                                    () => {
                                        window.history.back();
                                    }
                                }>
                                <i className="text-white fas fa-arrow-left"></i>
                            </button>

                            <div className="mx-auto">
                                <h2 className="v2 regular lh-base m-0">
                                    Editar Perfil
                                </h2>
                            </div>
                        </div>

                        <div className="text-center">
                            <img src={avatarSeleccionado?.avatarURL} alt="" className="mx-auto" width={140} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <a
                                className="mb-0 me-2 fw-bold fs-5 color-1"
                                onClick={() => {
                                    navigate('/update-avatar')
                                }}
                                href=""
                            >
                                <MDBIcon fas icon="edit" color="white" /> <span className="v2 regular">
                                    Cambiar Avatar
                                </span>
                            </a>
                        </div>
                        <MDBInput disabled
                            className="mb-3" type="text" name="gamerName" id="gamerName" label="Nombre de usuario"
                            value={updatedGamer.gamerName} onChange={handleInputChange}
                        />

                        <MDBInput
                            className="mb-3" type="text" name="gamerFullName" id="gamerFullName" label="Nombre completo"
                            value={updatedGamer.gamerFullName} onChange={handleInputChange}
                        />

                        <MDBInput
                            className="mb-3" type="number" name="gamerAge" id="gamerAge" label="Edad"
                            value={updatedGamer.gamerAge} onChange={handleInputChange} min={0}
                        />

                        <MDBInput
                            className="mb-3" type="email" id="gamerEmail" name="gamerEmail" label="Email"
                            value={updatedGamer.gamerEmail} onChange={handleInputChange}
                        />

                        <MDBInput
                            className="mb-3" type="text" name="gamerDNI" id="gamerDNI" label="RUT"
                            value={updatedGamer.gamerDNI} onChange={handleInputChange}
                        />

                        <MDBInput
                            label="Teléfono"
                            placeholder="+56 9 1234 5678"
                            type="text" name="gamerPhone" id="gamerPhone"
                            value={updatedGamer.gamerPhone}
                            onChange={handleInputChange}
                        />

                        {
                            regionId && <MDBSelect className="mb-3 mt-3" name="region_id" label="Región" onValueChange={(e) => setRegionId(e.value)}
                                data={region.map(region => (
                                    { text: region.stateName, value: region.id, defaultSelected: region.id == regionId }
                                ))}
                            />
                        }

                        {
                            cityId && <MDBSelect className="mb-3" name="city_id" label="Ciudad o comuna" onValueChange={(e) => {
                                setCityId(e.value);
                                setUpdatedGamer({
                                    ...updatedGamer,
                                    city_id: e.value
                                })
                            }}
                                data={city.map(city => (
                                    {
                                        text: city.cityName,
                                        value: city.id,
                                        defaultSelected: city.id == cityId
                                    }
                                ))}
                            />
                        }

                        <MDBInput
                            className="mb-3" type="password" id="gamerPassword" name="gamerPassword" label="(Editar contraseña actual)"
                            value={updatedGamer.gamerPassword} onChange={handleInputChange}
                        />

                        <div className="d-grid gap-2 mt-5">
                            <button className='v2 primary mx-4' onClick={updateGamer}>
                                <span className="d-flex justify-content-center align-items-center py-4 px-2 v2">Guardar</span>
                            </button>
                        </div>


                    </MDBCol>
                </MDBRow>
            </MDBContainer>



            <MDBSideNav isOpen={basicOpen} absolute getOpenState={(e) => setBasicOpen(e)} className="px-2 py-4">
                <img src={icon02} alt="Cerrar Sidebar" onClick={() => setBasicOpen(!basicOpen)} width={38} className="ms-auto d-block me-2" />
                <MDBContainer>
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol size="4" className="px-1">
                            <img
                                src={avatarSeleccionado?.avatarURL}
                                alt=""
                                className="mx-auto img-fluid"
                            />
                        </MDBCol>
                        <MDBCol size="8" className="text-left px-1">
                            <h5 className="fw-bold mb-0">{gamer.gamerName}</h5>
                            <MDBSideNavLink className="edit-profile-link p-0" onClick={() => {

                                navigate('/profile')
                            }}>
                                Editar mi perfil
                            </MDBSideNavLink>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <div className='side-close-session text-center' style={{ minHeight: '3rem' }}>
                    <hr className='mt-0 mb-2' />
                    <MDBSideNavLink onClick={() => {
                        navigate('/login');
                    }}>
                        <img src={icon01} alt="Cerrar Sesión" width={20} className="me-2" /> Cerrar sesión
                    </MDBSideNavLink>
                </div>
            </MDBSideNav>

            <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
                <MDBModalDialog centered>
                    <MDBModalContent className="text-center">
                        <MDBModalHeader>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='text-center'>
                                <h1 className="v2 regular">¡Éxito!</h1>
                            </div>
                            <div className='text-center mx-4 mt-4'>
                                <p className='v2 regular'>Tus Datos han sido guardados correctamente.</p>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-center mb-4">
                            <button className='v2 primary mx-4' onClick={() => {
                                navigate('/home')
                            }}>
                                <span className="d-flex justify-content-center align-items-center p-4 v2">Aceptar</span>
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


        </div>

    )

}

export default Profile;