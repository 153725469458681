import React, { useEffect, useState } from "react";
import MenuComponent from "../MenuComponent";
import { MDBCard, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBBtn, MDBContainer, MDBCardHeader, MDBIcon, MDBAnimation } from "mdb-react-ui-kit";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTZ from "moment-timezone";

const Prizes = () => {

    const navigate = useNavigate();

    const [state, setState] = useState(localStorage.getItem('state'));

    const [gamer, setGamer] = useState({
        id: null,
        gamerName: "",
        gamerEmail: "",
        gamerPassword: "",
        gamerCurrentScore: 0,
        gamerExperiencePoints: 0,
        avatar_id: 1,
    });

    useEffect(() => {

        if (!state) {
            navigate('/login');
        } else {
            const newGamer = JSON.parse(atob(state));
            setGamer(newGamer);
        }

    }, []);

    useEffect(() => {
        if (gamer.id != null) {
            //...
        }
    }, [gamer]);

    return (
        <>

            <div className="page-cubes d-flex flex-column min-vh-100">

                <MenuComponent onProfile={false} />
                <MDBContainer className="my-3">
                    
                </MDBContainer>
            </div>
        </>
    )
}

export default Prizes