import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
} from "mdb-react-ui-kit";
import imagenText from '../../img/textos/recuperarcuenta.png';
import userService from "../../services/users";

const ForgotPassword = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = React.useState(
    {
      gamerSignIn: '',
    }
  );

  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');
  const [varyingTitle, setVaryingTitle] = useState(''); // 'Error', 'Éxito'
  const [goToLogin, setGoToLogin] = useState(false);

  const btnFinalizar = async () => {
    //navigate('/new-password');

    const response = await userService.sendGamerPasswordRecoveryRequest(formData)

    if (response.status === 400) {
      setVaryingMessage(response.messages.error),
        setVaryingTitle('Error'),
        setErrorModal(!errorModal)
    } else {
      setVaryingMessage('Se ha enviado un correo a tu cuenta de correo electrónico.'),
        setErrorModal(!errorModal),
        setVaryingTitle('Éxito'),
        setGoToLogin(true);
    }

  };

  useEffect(() => {
    if (goToLogin && !errorModal) {
      navigate('/login');
    }
  }, [goToLogin, errorModal])

  return (
    <div className="page-cubes d-flex flex-column min-vh-100">
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>
          <MDBRow>
            <MDBCol className="col-md-6 col-12 mx-auto my-4 text-center">
              <h1 className="v2 regular">
                Recuperar cuenta
              </h1>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="col-md-6 col-12 mx-auto">
              <p className="mb-4 v2 regular ms-3">
                Si olvidaste tu contraseña realiza los siguientes pasos:
              </p>
              <p className="fw-bold v2 bold ms-3">Ingresa tu Nombre de Usuario o Email</p>
              <form action="" className="mb-4">
                <MDBInput
                  type="email"
                  id="emailInput"
                  className="mb-4"
                  label="Nombre de usuario o email"
                  onChange={(e) => setFormData({ ...formData, gamerSignIn: e.target.value })}
                  value={formData.gamerSignIn}
                />
                <div className="text-center">
                  <button className='v2 primary mx-4 mb-4' onClick={btnFinalizar} type="button">
                    <span className="d-flex justify-content-center align-items-center p-4 v2">Enviar</span>
                  </button>
                </div>
              </form>
            </MDBCol>
          </MDBRow>

          <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className='text-center mb-4'>
                    <h1 className="v2 regular">{errorModal && varyingTitle}</h1>
                  </div>
                  <div className='text-center mx-4 py-4'>
                    <p className='v2 regular'>{errorModal && varyingMessage}</p>
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </MDBContainer>
      </div>
    </div>
  )
};

export default ForgotPassword;
