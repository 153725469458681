import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import imagenPasaporte from "../img/textos/logo-pasaporte.png";
import imagenLogo from '../img/v2/logo-pasaporte.png';
import editarPerfil from '../img/textos/editar-perfil.png';
import icon01 from '../img/iconos/icon01.svg';
import icon02 from '../img/iconos/icon02.svg';
import icon03 from '../img/iconos/icon03.svg';
import icon04 from '../img/iconos/icon04.svg';
import notificationService from '../services/notifications'

import {
    House,
    Box2Heart,
    ExclamationCircle,
    Puzzle
} from 'react-bootstrap-icons'

import {
    MDBNavbar,
    MDBContainer, MDBScrollbar,
    MDBBtn,
    MDBSideNav,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBRow,
    MDBCol,
    MDBBadge,
    MDBIcon
} from 'mdb-react-ui-kit';
import hardcodeAvatar from '../services/hardcodeAvatar';

export default function MenuComponent({ onProfile }) {

    const [state, setState] = useState(localStorage.getItem('state'));
    const user = JSON.parse(atob(state));

    const navigate = useNavigate();
    const [basicOpen, setBasicOpen] = useState(false);
    const [userAvatar, setUserAvatar] = useState(null);
    const [hasNotifications, setHasNotifications] = useState(false);
    const [notifications, setNotifications] = useState(0);

    useEffect(() => {
        if (basicOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [basicOpen]);

    const handleLoadNotifications = async () => {
        const notificationResult = await notificationService.getAllGamerNotificationsByGamer(user.id)
        if (notificationResult.length > 0) {
            setNotifications(notificationResult.length)
            setHasNotifications(true)
        } else {
            setNotifications(0)
            setHasNotifications(false)
        }
    }
    const handleExternalURL = () => {
        window.open('https://pasaporte.expogamechile.cl/premios/', '_blank');
    }

    useEffect(() => {
        if (basicOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [basicOpen]);

    useEffect(() => {
        setUserAvatar(hardcodeAvatar(user.avatar_id));
        handleLoadNotifications();
    }, [])

    useEffect(() => {
        //console.log(userAvatar);
    }, [userAvatar])

    return (
        <>
            <MDBNavbar className="py-4">
                <MDBContainer className='d-flex align-items-center justify-content-between'>

                    <MDBBtn className="hamburguer-menu" onClick={() => setBasicOpen(!basicOpen)}>
                        <img src={icon03} alt="Nav menu" width={38} className="" />
                    </MDBBtn>

                    <img src={imagenLogo} alt="Expogame" className="logo-expogame mx-auto img-fluid" />

                    <div className='position-relative d-inline-block'>
                        <MDBBtn className={`bell-notification ${hasNotifications ? 'has-notifications' : ''}`}
                            onClick={
                                () => {
                                    navigate('/notifications')
                                }
                            }>
                            <img src={icon04} alt="Notificaciones" width={30} className="" />
                        </MDBBtn>
                        {
                            notifications > 0 && <MDBBadge color='success' light pill className='position-absolute translate middle' style={
                                {
                                    bottom: '0px',
                                    right: '5px',
                                }
                            }>
                                {notifications > 5 ? '5+' : notifications}
                            </MDBBadge>
                        }
                    </div>

                </MDBContainer>
            </MDBNavbar>
            <MDBSideNav isOpen={basicOpen} absolute getOpenState={(e) => setBasicOpen(e)}>
                <MDBBtn className='ms-auto d-block mt-3 btn-close me-3' color='none' onClick={() => setBasicOpen(!basicOpen)}></MDBBtn>
                <MDBContainer id="offcanvas-header">
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol size="4" className="px-1">
                            {
                                userAvatar && <img
                                    src={userAvatar.avatarURL}
                                    alt=""
                                    className="mx-auto img-fluid"
                                />
                            }
                        </MDBCol>
                        <MDBCol size="8" className="text-start px-1 ps-0">
                            <h4 className="fw-bold v2 regular text-white">{user.gamerName}</h4>
                            {
                                !onProfile && <>
                                    <a
                                        onClick={() => {
                                            navigate('/profile')
                                        }}
                                        className='react-link mt-2 d-block fs-6'
                                        style={
                                            {
                                                color: '#999',
                                                textDecoration: 'none',
                                            }
                                        }
                                    >
                                        Ver Perfil
                                    </a>
                                </>
                            }
                        </MDBCol>

                    </MDBRow>
                </MDBContainer>

                <MDBScrollbar sidenav tag='ul' suppressScrollX className='mt-2' style={{ maxHeight: 'calc(100% - 360px)' }}>
                    <MDBContainer>

                        <MDBSideNavLink onClick={() => {
                            navigate('/home')
                        }}
                            active={window.location.pathname === '/home'}
                        >
                            <House className='me-4 fs-4' /> <span className='fs-6'>Inicio</span>
                        </MDBSideNavLink>

                        <MDBSideNavLink onClick={() => {
                            navigate('/zones')
                        }}
                            active={window.location.pathname === '/zones'}
                        >

                            <Puzzle className='me-4 fs-4' /> <span className='fs-6'>Retos</span>
                        </MDBSideNavLink>

                        <MDBSideNavLink onClick={handleExternalURL}

                        >
                            <Box2Heart className='me-4 fs-4' /> <span className='fs-6'>Premios</span>
                        </MDBSideNavLink>

                        <MDBSideNavLink onClick={() => {
                            navigate('/faq')
                        }}
                            active={window.location.pathname === '/faq'}
                        >
                            <ExclamationCircle className='me-4 fs-4' /> <span className='fs-6'>Ayuda</span>
                        </MDBSideNavLink>

                    </MDBContainer>
                </MDBScrollbar>

                <MDBContainer id="offcanvas-social">
                    <MDBRow>
                        <MDBCol className='d-flex justify-content-start mb-2' id='rrss-text'>
                            <a href="http://www.instagram.com/expogamechile.cl" target="_blank" className='px-3 py-2'>
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.twitch.tv/hypeproducciones" target="_blank" className='px-3 py-2'>
                                <i className="fab fa-twitch"></i>
                            </a>
                            <a href="https://discord.gg/zYdebkP5AR" target="_blank" className='px-3 py-2'>
                                <i className="fab fa-discord"></i>
                            </a>
                            <a href="http://www.facebook.com/expogamechile" target="_blank" className='px-3 py-2'>
                                <i className="fab fa-facebook"></i>
                            </a>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <hr></hr>

                <MDBContainer id="offcanvas-footer">
                    <MDBRow>
                        <MDBCol>
                            <MDBSideNavLink onClick={() => {
                                navigate('/login');
                            }}
                            >
                                <i className="fas fa-sign-out-alt fs-6"></i>
                                <span className='fs-6 ms-3'>Cerrar Sesión</span>
                            </MDBSideNavLink>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBSideNav>
        </>
    )
}
