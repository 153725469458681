
const getZones = async () =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data= await fetch(process.env.REACT_APP_API_BASEURL + '/zones', requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
    return data

}
const getZoneById = async (zone_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data= await fetch(process.env.REACT_APP_API_BASEURL + `/zones/${zone_id}`, requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
    return data
}

const getZoneByUser = async (user_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/zone_users/user/${user_id}`, requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
    return data
}
const getZonesByVersion = async (version_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/zones/version/${version_id}`, requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
    return data
}

export default {
    getZoneByUser,
    getZoneById,
    getZones,
    getZonesByVersion
}