import { React } from "react";
import zoneService from "../services/zones";
import versionService from "../services/versions";


const App = async () =>{
    const currentVersion = await versionService.getCurrentVersion();
    return (currentVersion) 

}
export default App;